
import store from '@/store/store'
import client from '@/api/testMembersCreationApi'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'test-members-create-edit',
  mounted () {
    if (this.$route.params.generatedUid) {
      this.$store.dispatch('testMemberRequests/getTestMemberRequestByUid', { itemUid: this.$route.params.generatedUid })
        .then((res) => {
          this.clientId = res.clientId
          this.planId = res.planId
          this.zipCode = res.zipCode
          this.memberType = res.memberType
          this.generatedUid = res.generatedUid
          this.employeeId = res.employeeId
          this.certificateNumber = res.certificateNumber
          this.relationshipType = res.relationshipType
          this.childType = res.childType
        })
    }

    // iterate over clientConfig object stored in session and build client list
    const clientConfig = JSON.parse(sessionStorage.getItem('clientConfig')!)
    const clientConfigItems = clientConfig!.Items

    // iterate over each object and extract client id
    for (const item of clientConfigItems) {
      const clientId = item['productType#planId#configType'].replace('config#', '')
      this.clientIdOptions.push(clientId)
      const plansList = item.clientCoverage[0].clientPrimaryMedicalCoverage.plans
      this.clientIdPlanIdMapping[clientId] = [...plansList]
    }
  },
  data () {
    return {
      saveSubmit: true,
      clientId: undefined,
      planId: undefined,
      zipCode: undefined,
      memberType: undefined,
      generatedUid: undefined,
      employeeId: undefined,
      certificateNumber: undefined,
      relationshipType: undefined,
      childType: undefined,
      allPlans: undefined,
      clientIdOptions: [] as any[],
      clientIdPlanIdMapping: {},
      planIdOptions: [] as any[],
      memberTypeOptions: ['EmployeeOnly', 'Dependent', 'FamilySpouse'],
      relationshipTypeOptions: ['Spouse', 'Child'],
      childTypeOptions: ['Adult', 'Minor'],
      networkSeriesOptions: {},
      evenOptions: [] as number[],
      // clientNameList: this.getClientNameListFromStore(),
      clientIdError: undefined as string | undefined,
      planIdError: undefined as string | undefined,
      zipCodeError: undefined as string | undefined,
      memberTypeError: undefined as string | undefined,
      employeeIdError: undefined as string | undefined,
      certificateNumberError: undefined as string | undefined,
      relationshipTypeError: undefined as string | undefined,
      childTypeError: undefined as string | undefined,
      loaded: false
    }
  },
  methods: {
    async onClickSaveDraft () {
      this.$buefy.toast.open({
        indefinite: true,
        message: 'Your request is submitted and is being processed.',
        type: 'is-info',
        pauseOnHover: true
      })
      try {
        if (this.$route.params.generatedUid) {
          await this.updateDraft()
        } else {
          await this.createDraftTestMemberRequest()
        }
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Request processed successfully.',
          type: 'is-success',
          pauseOnHover: true
        })
        this.$router.push('/test-members-creation')
      } catch (e: any) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Processing failed with following error: ${e.message}.`,
          type: 'is-danger',
          pauseOnHover: true
        })
      }
    },
    async onClickSubmit () {
      this.$buefy.toast.open({
        indefinite: true,
        message: 'Your request is submitted and is being processed.',
        type: 'is-info',
        pauseOnHover: true
      })
      try {
        if (this.$route.params.generatedUid) {
          await this.updateSubmitted()
        } else {
          await this.createSubmitTestMemberRequest()
        }
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Request processed successfully.',
          type: 'is-success',
          pauseOnHover: true
        })
        this.$router.push('/test-members-creation')
      } catch (e: any) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Processing failed with following error: ${e.message}.`,
          type: 'is-danger',
          pauseOnHover: true
        })
      }
    },
    clientIdSelectionChanged () {
      this.planIdOptions = this.clientIdPlanIdMapping[this.clientId!]
    },
    async createDraftTestMemberRequest () {
      await client.createTestMemberRequest({
        clientId: this.clientId,
        planId: this.planId,
        zipCode: this.zipCode,
        memberType: this.memberType,
        requestStatus: 'Draft',
        employeeId: this.employeeId,
        certificateNumber: this.certificateNumber,
        relationshipType: this.relationshipType,
        childType: this.childType
      })
    },
    async updateDraft () {
      await client.updateTestMemberRequest({
        clientId: this.clientId,
        planId: this.planId,
        zipCode: this.zipCode,
        memberType: this.memberType,
        requestStatus: 'Draft',
        generatedUid: this.generatedUid,
        employeeId: this.employeeId,
        certificateNumber: this.certificateNumber,
        relationshipType: this.relationshipType,
        childType: this.childType
      })
    },
    async createSubmitTestMemberRequest () {
      await client.createTestMemberRequest({
        clientId: this.clientId,
        planId: this.planId,
        zipCode: this.zipCode,
        memberType: this.memberType,
        requestStatus: 'Submitted',
        employeeId: this.employeeId,
        certificateNumber: this.certificateNumber,
        relationshipType: this.relationshipType,
        childType: this.childType
      })
    },
    async updateSubmitted () {
      await client.updateTestMemberRequest({
        clientId: this.clientId,
        planId: this.planId,
        zipCode: this.zipCode,
        memberType: this.memberType,
        requestStatus: 'Submitted',
        generatedUid: this.generatedUid,
        employeeId: this.employeeId,
        certificateNumber: this.certificateNumber,
        relationshipType: this.relationshipType,
        childType: this.childType
      })
    },
    displayDependentFields () {
      return this.memberType === 'Dependent'
    },
    displayChildTypeSelector () {
      return this.relationshipType === 'Child'
    },
    addNetwork () {
    },
    deleteNetwork (index: Number) {
    },
    getNetworkSeriesList () {
    },
    getNetworkSeriesFromStore () {
      return store.getters['networkConfig/getNetworkSeriesOptions']
    },
    getClientNameListFromStore () {
      return store.getters['networkConfig/getClientNameList']
    },
    validateClientId () {
    },
    validatePlanId () {
    },
    validateZipCode () {
    },
    validateMemberType () {
      if (this.memberType !== 'Dependent') {
        this.employeeId = undefined
        this.certificateNumber = undefined
        this.relationshipType = undefined
        this.childType = undefined
      }
    },
    validateEmployeeId () {
    },
    validateCertificateNumber () {
    },
    validateRelationshipType () {
      if (this.relationshipType !== 'Child') {
        this.childType = undefined
      }
    },
    validateChildType () {
    }
  }
})
